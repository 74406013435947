import React, { Component, useState, useEffect } from "react";
import { withRouter, Redirect } from "react-router-dom";
import _ from "lodash";
import Moment from "moment";
import {
  Card,
  CardGroup,
  Container,
  Row,
  Col,
  Button,
  Dropdown,
  ButtonGroup,
  InputGroup,
  Form,
  FormControl,
  Spinner,
} from "react-bootstrap";
import { DateField, SelectField, TagsField } from "./shared/FormControls";
import { FaSistrix, FaChevronDown, FaChevronUp, FaBurn } from "react-icons/fa";
import Page from "./Page";
import TopMenu from "../containers/TopMenuContainer";
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { useDispatch } from 'react-redux'
import * as api from "../store/api"

//Note: Using react-bootstrap-table and not react-bootstrap-table-2(next) as it doesn't support header-group
var ReactBsTable = require("react-bootstrap-table");
var BootstrapTable = ReactBsTable.BootstrapTable;
var TableHeaderColumn = ReactBsTable.TableHeaderColumn;
class Dashboard extends Page {
  state = {
    busy: true,
    busySearch: true,
    datesUpdated: true,
    operators: [],
    measures: [],
    filters: [ //Used to show selected filter values on the pdf
      {key: 'startDate' , title: 'Start Date'},
      {key: 'endDate' , title: 'endDate'},
      {key: 'operators' , title: 'Operator'},
      {key: 'measures' , title: 'Measures'},
    ],
    reportSearchTerms: {
      operators: [],
      measures: [],
      startDate: new Moment().subtract(1, "months"), //undefined
      endDate: new Moment(), //undefined
    },
    reportSearchResult: null,
    reportBusy: false,
    isBusyTag: false,
    tableColumns: null,
    pdfHeader: null,
    logsData: null,
    downloadBusy: false,
    searchUpdate: false,
  };

  componentDidMount() {
    let self = this;
    self.setState({
      measures: [
        { value: "Berley", label: "Total Berley" },
        { value: "Berley FP", label: "Total Berley FP" },
        { value: "Time", label: "Total Time" },
        { value: "Interactions", label: "Total Interactions" },
        { value: "Sharks", label: "Total Sharks" },
        { value: "Participants", label: "Total Participants" },
      ],
    });

    if(self.props.operators && !self.props.operators.length){
      //Query to get operators
      self.props.getOperators((response) => {
        //console.log(response);
        if (response && _.has(response, "success") && response.success) {
          let operators = response.data;
          self.getOperatorsCB(operators);
        }
      });
    }else{
      self.getOperatorsCB(self.props.operators);
    }
    
  }

  getOperatorsCB = (operators) => {
    let self = this;
    self.setState(
      {
        operators: operators,
        //busy: false,
      },
      () => {
        //Populate report table
        self.onReportSearchSubmit();
      }
    );
  }
  
  //
  populateTableData = (responseData, startDate, endDate, columns, pdfHeader) => {
    let self = this;
    var tableData = [];
    let counter = 0;
    endDate = endDate.add(1, "days");
    //todo: check startData < endData
    while (startDate.format("DDMMYYYY") !== endDate.format("DDMMYYYY")) {
      let dataRow = {};
      let values = [];
      let rowHasData = false;
      //Get records with matching startDate
      _.filter(responseData, function(rd) {
        if (rd.date == startDate.format("DD/MM/YYYY")) {
          let operatorName = rd["operator_boat_name"];//rd["operator_name"];
          //Fill values for columns - operator_name_measure_name
          _.forEach(rd["measures"], function(val, m) {
            let key = self.formatKeyField(operatorName + "_" + m);
            values[key] = val;
            //console.log("Value.." , val);
          });
        }
      });

      //Now add records to the table row
      _.forEach(columns, function(cc, i) {
        let key = cc["field"];
        //console.log(key);
        //let value = values && values[key] ? values[key] : "-";
        let value = values[key];
        if(value != undefined){
          rowHasData = true;
        }
        switch (key) {
          case "id":
            value = counter++;
            break;
          case "date":
            value = startDate.format("DD/MM/YYYY");
        }
        dataRow[key] = value;
      });

      //Only show rows that have data
      if(rowHasData){
        tableData.push(dataRow);
      }
      //console.log("Table data .... ", tableData);
      startDate = startDate.add(1, "days");
    }

    console.log("tableData...", tableData);
    self.setState({
      busySearch: false,
      datesUpdated: false,
      reportBusy: false,
      searchUpdate: false,
      busy: false,
      reportSearchResult: tableData,
      logsData: responseData,
      tableColumns: columns,
      pdfHeader: pdfHeader
    });
  };

  //convert to lowercase, replace white space with '_'
  formatKeyField = (key) => {
    let lcase = _.lowerCase(key);
    return lcase ? lcase.replace(/\W/g, "_") : key;
  };

  setReportSearchFieldValue = (field, value) => {
    let rstMutable = this.state.reportSearchTerms;
    rstMutable[field] = value;
    this.setState(
      {
        reportSearchTerms: rstMutable,
      },
      () => {
        //console.log(this.state.reportSearchTerms);
      }
    );
  };

  handleDateChange = (field, date) => {
    let self = this;
    self.setState({
      datesUpdated: true,
      searchUpdate: true,
    });

    this.setReportSearchFieldValue(field, new Moment(date));
  };

  handleTagChange = (type, tag) => {
    //console.log(type);
    //console.log(tag);
    let self = this,
      reportSearchTermsMutable = this.state.reportSearchTerms,
      index = reportSearchTermsMutable[type]
        ? reportSearchTermsMutable[type].indexOf(tag.value)
        : -1;
    self.setState({searchUpdate: true}, () => {
      if (index < 0) {
        reportSearchTermsMutable[type] = reportSearchTermsMutable[type]
          ? reportSearchTermsMutable[type]
          : [];
        reportSearchTermsMutable[type].push(tag.value);
        self.setState(
          {
            reportSearchTerms: reportSearchTermsMutable,
            isBusyTag: false,
          },
          () => {
            console.log("tags changed", this.state.reportSearchTerms[type]);
          }
        );
      } else {
        self.setState({
          isBusyTag: false,
        });
      }
    });
  };

  shouldRemoveTag = (type, tag) => {
    console.log(tag);
    let reportSearchTermsMutable = this.state.reportSearchTerms,
      index = reportSearchTermsMutable[type].indexOf(tag.value);

    if (index > -1) {
      reportSearchTermsMutable[type].splice(index, 1);
    }

    this.setState(
      {
        reportSearchTerms: reportSearchTermsMutable,
      },
      () => {
        console.log("tags changed", this.state.reportSearchTerms[type]);
      }
    );
  };

  buildTableColumn = (operatorName, measures, tableColumns, pdfHeader) => {
    let self = this
    let head = {
      field: operatorName,
      title: operatorName,
      content: operatorName,
      row: 0,
      colSpan: measures.length,
      csvHeader: operatorName,
    };
    tableColumns.push(head);
    pdfHeader[0].push(head); //row: 0
    if (measures) {
      _.forEach(measures, function(mm, ii) {
        let measureName = _.isObject(mm) ? mm.value : mm;
        let field = self.formatKeyField(operatorName + "_" + measureName);
        let head = {
          field: field,
          title: measureName,
          content: measureName,
          row: 1,
        };
        tableColumns.push(head);
        pdfHeader[1].push(head); //row: 1
      });
    }

    return {tableColumns, pdfHeader};
  }

  buildReportTable = (responseData, startData, endDate) => {
    let self = this;
    //First - find all column headers
    //If no measures selected - show all measures
    let searchMeasures = this.state.reportSearchTerms.measures;
    let measures =
      searchMeasures && searchMeasures.length
        ? searchMeasures
        : this.state.measures;

    let tableColumns = [
      { field: "id", title: "Id", content: "id", row: 0, rowSpan: 2 , hidden: true},
      { field: "date_header", title: "Date", content: "date_header", row: 0, colSpan: 1 },
      { field: "date", title: "Total ->", content: "title", row: 1 },
    ];

    let pdfHeader = [
      [
        //{field: "id", title: "Id", content: "Id", row: 0, rowSpan: 2 , hidden: true},
        { field: "date_header", title: "Date", content: "Date", row: 0, colSpan: 1 },
      ],
      [
        { field: "date", title: "Total ->", content: "Total ->", row: 1 },
      ]
    ];
    //If no operators selected - show all operators
    let searchOperators = this.state.reportSearchTerms.operators;
    //Find operators object
    let selectedOperators = _.filter(this.state.operators, function(op){ return searchOperators.includes(op.value); });
    let operators =
    selectedOperators && selectedOperators.length
        ? selectedOperators
        : this.state.operators;

      let result = null
    _.forEach(operators, function(oo, i) {
      let operatorName = _.isObject(oo) ? oo.value : oo;
      //If operator has more than 1 boat display result for both the boats
      if(oo['boats'] && oo['boats'].length){
        //build table column for each boat
        _.forEach(oo['boats'], function(boat, bi) {
          let opName = boat['name'];
          result = self.buildTableColumn(opName, measures, tableColumns, pdfHeader);
          tableColumns = result ? result.tableColumns : tableColumns;
          pdfHeader = result ? result.pdfHeader : pdfHeader;
        });
      }else{
        //Each operators has boats so this block won't get executed??
        result = self.buildTableColumn(operatorName, measures, tableColumns, pdfHeader);
        tableColumns = result ? result.tableColumns : tableColumns;
        pdfHeader = result ? result.pdfHeader : pdfHeader;
      }
    });
    self.populateTableData(responseData, startData, endDate, tableColumns, pdfHeader);
  };

  onReportSearchSubmit = (e) => {
    let self = this;
    if (e) {
      e.preventDefault();
    }
    this.setState(
      {
        reportSearchResult: null,
        reportBusy: true,
        tableColumns: [],
        pdfHeader: [],
        logsData: self.state.datesUpdated ? null : self.state.logsData,
      },
      () => {
        let startDate = _.cloneDeep(self.state.reportSearchTerms.startDate); //Moment object - use deepClone else processing this value changes the searchTerm startDate values
        let endDate = _.cloneDeep(self.state.reportSearchTerms.endDate); //Moment object

        //If dates updated make api call to get data
        if(self.state.datesUpdated){
            self.props.getLogsReport(
            startDate.format("DD MMM YYYY"),
            endDate.format("DD MMM YYYY"),
            self.state.operators,
            (response) => {
                //console.log(response);
                let counter = 0;
                if (response && _.has(response, "success") && response.success) {
                let responseData = response.data;
                console.log("responseData...", responseData);
                self.buildReportTable(responseData, startDate, endDate);
                } else {
                self.setState({
                    busySearch: false,
                    datesUpdated: false,
                    reportBusy: false,
                    busy: false,
                });
                }
            }
            );
      }else{
          //Just show columns and measures selected (no need to refetch the data)
          let responseData = self.state.logsData;
          console.log("Response Data ...", responseData);
          self.buildReportTable(responseData, startDate, endDate);
      }
      }
    );
  };

  resetReportSearchTerms = () => {
    this.setState(
      {
        reportSearchTerms: {
          operators: [],
          measures: [],
          startDate: new Moment().subtract(1, "months"), //undefined
          endDate: new Moment(), //undefined
        },
        reportSearchResult: null,
        reportBusy: false,
        tableColumns: [],
        pdfHeader: []
      },
      () => {
        //console.log(this.state.reportSearchTerms);
      }
    );
  };

  didSelectPrint = () => {
    let self = this
    console.log("search term...", self.state.reportSearchTerms);
    var doc = new jsPDF()//'p', 'pt'
    var totalPagesExp = '{total_pages_count_string}'
    
    var hd = self.state.pdfHeader.length == 2  ? self.state.pdfHeader[[1]] : [];
    var body = []
    var data = self.state.reportSearchResult;

    for (var i = 0; i < data.length; i++) {
      var row = []
      for (var j = 0; j < hd.length; j++) { //Header
        var key = hd[j]['field'];
        row.push(data[i][key])
      } console.log("row...", row);
      
      body.push(row)
    }

    var doc = new jsPDF()

    doc.setFontSize(14)
    doc.text('Report', 14, 22)
    doc.setFontSize(8)
    doc.setTextColor(100)

    var text = "";
      for (var f=0; f < self.state.filters.length; f++) {
        var ft = self.state.filters[f];
        var key = ft['key'];
        var value = self.state.reportSearchTerms[key];
        text += "\n" + ft['title'] + ": ";
        switch (key) {
          case "startDate":
          case "endDate":
            text += value.format("DD/MM/YYYY");
            break;
          case "operators":
              if(value.length){
                text += _.map(value).join(', ')
              }else{
                text += _.map(self.state.operators, 'label').join(', ');
              }
              break;
          case "measures":
            if(value.length){
              text += _.map(value).join(', ')
            }else{
              text += _.map(self.state.measures, 'label').join(', ');
            }
            break;
        }
      }
    // jsPDF 1.4+ uses getWidth, <1.4 uses .width
    var pageSize = doc.internal.pageSize
    var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
    var textSplit = doc.splitTextToSize(text, pageWidth - 35, {})
    doc.text(textSplit, 14, 25)

    doc.autoTable({
      head: self.state.pdfHeader,
      body: body,
      theme: 'plain',
      startY: 45,
      showHead: 'everyPage',
      tableLineWidth: 0.1,
      headStyles: {
        fontStyle : 'bold',
        fontSize: 5,
        lineWidth: 0.1,
        fillColor: [255,255,255],
        color: [0,0,0]
      },
      bodyStyles: {
        fontStyle: 'normal',
        fontSize: 5,
        lineWidth: 0.1,
      },
      didDrawPage: function (data) {
        // Footer
        var str = 'Page ' + doc.internal.getNumberOfPages()
        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === 'function') {
          str = str + ' of ' + totalPagesExp
        }
        doc.setFontSize(5)
        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize
        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
        doc.text(str, data.settings.margin.left, pageHeight - 10)
      }
    })

    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp)
    }

    doc.save('Dailylogs.pdf');
  }

  indication = () => {
    return this.state.busySearch ? (
      <Spinner animation="grow" variant="primary" />
    ) : (
      "No messages found."
    );
  };

  render() {
    if (!this.props.currentUser) {
      return <Redirect push to="/login" />;
    }

    if (this.state.busy) {
      return (
        <div className="text-center">
            <Spinner animation="grow" variant="primary" className="mt-5"/>
        </div>
      );
    }

    let self = this,
      operators = self.state.operators,
      measures = self.state.measures;

    return (
      <div>
      <Container className="controls">
        <Row className="menuRow pb-5">
          <TopMenu />
        </Row>

        <Row className="contentRow">
          <Col md={12}>
            <h4 className='pb-5'>Daily log report</h4>
            <form id="reportSearch" onSubmit={self.onReportSearchSubmit}>
              <div className="form-container form-container--search">
                <Row className="w-100">
                  <Col md={10} className="form-wrapper">
                    <Row>
                      <Col md={6} className="form-wrapper">
                        <DateField
                          id="date_from"
                          label="From Date"
                          selected={self.state.reportSearchTerms.startDate}
                          dateChange={(selectedDate) => {
                            self.handleDateChange(
                              "startDate",
                              selectedDate.toUTCString()
                            );
                          }}
                          maxDate={
                            self.state.reportSearchTerms.endDate
                              ? self.state.reportSearchTerms.endDate
                              : null
                          }
                        />
                      </Col>
                      <Col md={6} className="form-wrapper">
                        <DateField
                          id="date_to"
                          label="To Date"
                          selected={self.state.reportSearchTerms.endDate}
                          dateChange={(selectedDate) => {
                            self.handleDateChange(
                              "endDate",
                              selectedDate.toUTCString()
                            );
                          }}
                          minDate={
                            self.state.reportSearchTerms.startDate
                              ? self.state.reportSearchTerms.startDate
                              : null
                          }
                          maxDate={new Date()}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} className="form-wrapper">
                        <TagsField
                          id="operators"
                          label="Operators"
                          placeholder="Operators..."
                          options={operators}
                          selectedOptions={
                            self.state.reportSearchTerms.operators
                          }
                          selectChange={(selectedOption) => {
                            self.handleTagChange("operators", selectedOption);
                          }}
                          shouldRemoveTag={(selectedOption) => {
                            self.shouldRemoveTag("operators", selectedOption);
                          }}
                          isLoading={self.state.isBusyTag}
                        />
                      </Col>
                      <Col md={6} className="form-wrapper">
                        <TagsField
                          id="measures"
                          label="Measures"
                          placeholder="Measures..."
                          options={measures}
                          selectedOptions={
                            self.state.reportSearchTerms.measures
                          }
                          selectChange={(selectedOption) => {
                            self.handleTagChange("measures", selectedOption);
                          }}
                          shouldRemoveTag={(selectedOption) => {
                            self.shouldRemoveTag("measures", selectedOption);
                          }}
                          isLoading={self.state.isBusyTag}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={2} className="form-wrapper text-right">
                    <Button
                      variant="outline-primary btn-md mb-4 "
                      className={"btn-blue d-block w-100"}
                      type="button"
                      onClick={(e) => {
                        self.resetReportSearchTerms();
                      }}
                    >
                      Reset
                    </Button>
                    <Button
                      disabled={self.state.reportBusy}
                      type="submit"
                      className={
                        "btn-md d-block w-100 mb-4 " +
                        (this.state.reportBusy ? "disabled" : "btn-blue")
                      }
                    >
                      {self.state.reportBusy ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Search"
                      )}
                    </Button>
                    <Button
                      disabled={self.state.downloadBusy}
                      type="button"
                      onClick={(e) => {
                        self.didSelectPrint();
                      }}
                      className={
                        "btn-md d-block w-100 " +
                        (this.state.downloadBusy || this.state.searchUpdate ? "disabled" : "btn-blue")
                      }
                    >
                      {self.state.downloadBusy ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Download PDF"
                      )}
                    </Button>
                  </Col>
                </Row>
              </div>
            </form>
          </Col>
        </Row>
      </Container>
      {!self.state.reportBusy &&
                self.state.reportSearchResult != null ? (
                  <BootstrapTable
                   id="bar"
                    data={self.state.reportSearchResult}
                    keyField="id"
                  >
                    {self.state.tableColumns.map((column, indx) => {
                      return (
                        <TableHeaderColumn
                          key={indx + 1}
                          dataField={column.field}
                          csvHeader={column.csvHeader ? column.csvHeader : ""}
                          row={column.row ? column.row : "0"}
                          rowSpan={column.rowSpan ? column.rowSpan : ""}
                          colSpan={column.colSpan ? column.colSpan : ""}
                          className={column.className ? column.className : ""}
                          hidden={column.hidden ? true : false}
                        >
                          {" "}
                          {column.title}{" "}
                        </TableHeaderColumn>
                      );
                    })}

                  </BootstrapTable>
                ) : null}
      </div>
    );
  }
}

const DashboardWithSettings = (props) => {
  const [settings, setSettings] = useState([])
  const dispatch = useDispatch()

  useEffect(() => {
      dispatch(api.getSettings()).then((r) => {
          setSettings(r)
      })
  }, [])

  return <Dashboard {...props} settings={settings}/>
}

export default withRouter(DashboardWithSettings);