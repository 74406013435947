import React, { useEffect, useState } from 'react'
import TopMenu from "../containers/TopMenuContainer";
import {
    Card,
    CardGroup,
    Container,
    Row,
    Col,
    Button,
    Dropdown,
    ButtonGroup,
    InputGroup,
    Form,
    FormControl,
    Spinner,
} from "react-bootstrap";
import { DateField, SelectField, TagsField } from "./shared/FormControls";
import { useDispatch } from 'react-redux'
import * as api from "../store/api"
import Moment from "moment";
import { getSetting } from '../helpers/Settings'

const DATE_FORMAT = "YYYY-MM-DD"

export function Settings(props) {
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [busy, setBusy] = useState(true)
    const [settings, setSettings] = useState([])
    const dispatch = useDispatch()

    useEffect(() => {
        setBusy(true)
        dispatch(api.getSettings()).then((r) => {
            const fortnightPeriod = getSetting('fortnight-period', r)
            setStartDate(Moment(fortnightPeriod.value.fromDate, DATE_FORMAT).toDate())
            setEndDate(Moment(fortnightPeriod.value.toDate, DATE_FORMAT).toDate())

            setSettings(r)
            setBusy(false)
        })
    }, [])

    const onSubmit = () => {
        setBusy(true)
        const fortnightPeriod = getSetting('fortnight-period', settings)
        fortnightPeriod.value.fromDate = Moment(startDate).format(DATE_FORMAT)
        fortnightPeriod.value.toDate = Moment(endDate).format(DATE_FORMAT)
        dispatch(api.updateSetting(fortnightPeriod)).then((r) => {
            setBusy(false)
        })
    }

    return (
        <div>
            <Container className="controls">
                <Row className="menuRow pb-5">
                    <TopMenu />
                </Row>

                <Row className="contentRow">
                    <Col md={12}>
                        <h4 className='pb-5'>Settings</h4>
                        <h5 className='pb-2'>Fortnight Period</h5>
                        <form id="reportSearch" /*onSubmit={onSubmit}*/>
                            <div className="form-container form-container--search">
                                <Row className="w-100">
                                    <Col md={10} className="form-wrapper">
                                        <Row>
                                            <Col md={6} className="form-wrapper">
                                                <DateField
                                                    id="date_from"
                                                    label="From Date"
                                                    selected={startDate}
                                                    dateChange={(selectedDate) => {
                                                        setStartDate(selectedDate)
                                                    }}
                                                    maxDate={
                                                        endDate
                                                        ? endDate
                                                        : null
                                                    }
                                                />
                                            </Col>
                                            <Col md={6} className="form-wrapper">
                                                <DateField
                                                    id="date_to"
                                                    label="To Date"
                                                    selected={endDate}
                                                    dateChange={(selectedDate) => {
                                                        setEndDate(selectedDate)
                                                        /*
                                                        handleDateChange(
                                                            "endDate",
                                                            selectedDate.toUTCString()
                                                        );*/
                                                    }}
                                                    minDate={
                                                        startDate
                                                        ? startDate
                                                        : null
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={2} className="form-wrapper text-right">
                                        <Button
                                            disabled={busy}
                                            type="submit"
                                            className={
                                                "btn-md d-block w-100 mb-4 " +
                                                (busy ? "disabled" : "btn-blue")
                                            }
                                            onClick={onSubmit}
                                        >
                                            {busy ? (
                                                <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                />
                                            ) : (
                                                "Save"
                                            )}
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </form>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}