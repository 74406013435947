import React, { Component } from 'react'
import {
    withRouter,
    Redirect
} from 'react-router-dom'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
import _ from 'lodash'
import moment from 'moment'

class Login extends Component {

    state = {
        login: false,
        signup: false,
        username: "",
        password: "",
        forgotPassword: false
    }

    onLoginClick = () => {
        if (this.state.username === "") {
            alert("Username is blank")
            return
        }
        this.props.login(this.state.username, this.state.password)
    }

    navigateTo = (url) => {
        this.setState({ [url]: true })
    }

    render() {
        const { classes } = this.props;

        if (this.props.currentUser) {
            return <Redirect push to="/" />;
        }
        if (this.state.forgotPassword) {
            return <Redirect push to="/forgotpassword" />;
        }

        if (this.state.signup) {
            return <Redirect push to="/signup" />;
        }

        if (this.props.apiStatus == "API_LOADING") {
            return (
                <div>
                    <div>
                        Loading...
                    </div>
                </div>
            )
        }

        return (

            <Container>
                <Row className="menuRow">
                    <Col md={12}>
                        <h1>Login</h1>
                     </Col>
                </Row>
                <Row className="contentRow">
                    <Col md={12}>
                        <Form>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="text" placeholder="Enter email"
                                    onChange={(event) => this.setState({ username: event.target.value })} />
                                <Form.Text className="text-muted">
                                    We'll never share your email with anyone else.
                                </Form.Text>
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" placeholder="Password"
                                    onChange={(event) => this.setState({ password: event.target.value })} />
                            </Form.Group>
                            <Button variant="primary" onClick={this.onLoginClick}>
                                Submit
                            </Button>
                        </Form>
                    </Col>
                </Row>

            </Container>

        );
    }
}

export default withRouter(Login)