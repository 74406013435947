import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";
import _ from "lodash";
import Moment from "moment";
import {
  Card,
  CardGroup,
  Container,
  Row,
  Col,
  Button,
  Dropdown,
  ButtonGroup,
  InputGroup,
  Form,
  FormControl,
  Spinner,
} from "react-bootstrap";
import {
  DateField,
  SelectField,
  TagsField,
  TextField,
} from "./shared/FormControls";
import { FaSistrix, FaChevronDown, FaChevronUp, FaBurn } from "react-icons/fa";
import Page from "./Page";
import TopMenu from "../containers/TopMenuContainer";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import cellEditFactory from "react-bootstrap-table2-editor";

class BucketWeightManager extends Page {
  state = {
    busy: false,
    busyAdd: false,
    busyTable: false,
    busyDelete: false,
    bucketDeleteId: null,
    busyUpdate: false,
    bucketEditId: null,
    operators: [],
    fields: {
      operator: "",
      fromDate: new Moment().subtract(1, "months"), //undefined
      toDate: new Moment(), //undefined,
      smallBinKgWeight: 0,
      largeBinKgWeight: 0,
      bucketKgWeight: 0,
    },
    tableData: null,
    tableColumns: [],
  };

  componentDidMount() {
    let self = this;
    //set table columns
    console.log(self.props.operators);
    if (self.props.operators && !self.props.operators.length) {
      self.setState(
        {
          busy: true,
        },
        () => {
          //Query to get operators
          self.props.getOperators((response) => {
            //console.log(response);
            if (response && _.has(response, "success") && response.success) {
              let operators = response.data;
              self.setState({
                operators: operators,
                busy: false,
              });
            }
          });
        }
      );
    } else {
      self.setState({
        operators: self.props.operators,
      });
    }
    self.buildBucketWeightTable();
  }

  formatDate = (date) => {
    let dateStr = Moment(date).format("YYYY-MM-DD"); //this.props.dailyLog.date.format("YYYY-MM-DD");
    var currentTime = Moment();
    let time = Moment(currentTime).format("hh:mm a");
    return Moment(dateStr + " " + time, "YYYY-MM-DD hh:mm a").toDate();
  };

  buildBucketWeightTable = () => {
    let self = this;
    self.setState(
      {
        busyTable: true,
      },
      () => {
        //Query to get operators
        self.props.getBucketWeights((response) => {
          //console.log(response);
          if (response && _.has(response, "success") && response.success) {
            //console.log("Table content...", response.data);
            self.setState({
              busyTable: false,
              tableData: response.data,
              //reset busy states
              busyAdd: false,
              busyDelete: false,
              busyUpdate: false,
              bucketEditId: null,
            });
          }
        });
      }
    );
  };

  onAddBucketWeight = (e) => {
    let self = this;
    if (e) {
      e.preventDefault();
    }
    this.setState(
      {
        busyAdd: true,
      },
      () => {
        let fromDate = _.cloneDeep(self.state.fields.fromDate); //Moment object - use deepClone else processing this value changes the searchTerm fromDate values
        let toDate = _.cloneDeep(self.state.fields.toDate); //Moment object

        self.props.addBucketWeight(
          self.formatDate(fromDate),
          self.formatDate(toDate),
          self.state.fields.operator,
          self.state.fields.smallBinKgWeight,
          self.state.fields.largeBinKgWeight,
          self.state.fields.bucketKgWeight,
          (response) => {
            //console.log(response);
            let counter = 0;
            if (response && _.has(response, "success") && response.success) {
              let responseData = response.data;
              //console.log("responseData...", responseData);
              //todo display table
              self.buildBucketWeightTable();
            } else {
              self.setState({
                busyAdd: false,
              });
            }
          }
        );
      }
    );
  };

  onDeleteBucketWeight = (self, bucketId) => {
    if (window.confirm("Are you sure you want to delete this record?")) {
      let tableData = self.state.tableData;
      self.setState(
        {
          bucketDeleteId: bucketId,
          tableData: [], //sets to empty first and then resets back below... (just to show the deleteBusy state)
        },
        () => {
          this.setState(
            {
              busyDelete: true,
              tableData: tableData,
            },
            () => {
              self.props.deleteBucketWeight(
                self.state.bucketDeleteId,
                (response) => {
                  if (
                    response &&
                    _.has(response, "success") &&
                    response.success
                  ) {
                    self.buildBucketWeightTable();
                  } else {
                    self.setState({
                      busyDelete: false,
                    });
                  }
                }
              );
            }
          );
        }
      );
    }
  };

  onShowEditBucketWeight = (row, rowIndex) => {
    let self = this;
    let tableData = self.state.tableData;
    console.log(row);
    console.log(rowIndex);
    console.log(tableData);
    let fields = _.find(tableData, function(td) {
      return td.id == row.id;
    });

    fields.toDate = Moment(fields.toDate, "DD/MM/YYYY HH:mm a");
    fields.fromDate = Moment(fields.fromDate, "DD/MM/YYYY HH:mm a");

    this.setState({
      fields: fields,
      bucketEditId: fields.id,
    });
  };

  onUpdateEditBucketWeight = (e) => {
    let self = this;
    if (e) {
      e.preventDefault();
    }
    console.log(self.state.fields);
    this.setState(
      {
        busyUpdate: true,
      },
      () => {
        let fromDate = _.cloneDeep(self.state.fields.fromDate); //Moment object - use deepClone else processing this value changes the searchTerm fromDate values
        let toDate = _.cloneDeep(self.state.fields.toDate); //Moment object

        self.props.updateBucketWeight(
          self.state.fields.id,
          self.formatDate(fromDate),
          self.formatDate(toDate),
          self.state.fields.operator,
          self.state.fields.smallBinKgWeight,
          self.state.fields.largeBinKgWeight,
          self.state.fields.bucketKgWeight,
          (response) => {
            if (response && _.has(response, "success") && response.success) {
              self.buildBucketWeightTable();
            }
            self.resetBucketWeightFields();
          }
        );
      }
    );
  };

  tableActions(cell, row, rowIndex, formatExtraData) {
    let self = formatExtraData.self;
    //cell: is the 'id' for this row
    let busyDelete = self.state.bucketDeleteId == row.id ? true : false;
    if (self.state.busyDelete) {
      console.log("Show loading....");
    }
    return (
      <>
        <Button
          variant="link"
          className="blue"
          onClick={(e) => self.onShowEditBucketWeight(row, rowIndex)}
        >
          Edit
        </Button>

        <Button
          variant="link"
          className="blue"
          onClick={(e) => self.onDeleteBucketWeight(self, cell)}
        >
          {busyDelete ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            "Delete"
          )}
        </Button>
      </>
    );
  }

  resetBucketWeightFields = () => {
    this.setState(
      {
        fields: {
          operator: "",
          fromDate: new Moment().subtract(1, "months"), //undefined
          toDate: new Moment(), //undefined
          smallBinKgWeight: 0,
          largeBinKgWeight: 0,
          bucketKgWeight: 0,
        },
        busyAdd: false,
      },
      () => {
        //console.log(this.state.fields);
      }
    );
  };

  setFieldValue = (fieldId, value) => {
    var self = this;
    var mutableFields = self.state.fields;
    if (fieldId) {
      mutableFields[fieldId] = value;
    }
    self.setState({ fields: mutableFields });
  };

  handleChange = (e) => {
    var self = this;
    self.setFieldValue(e.target.id, e.target.value);
  };

  handleDateChange = (name, date) => {
    let self = this;
    console.log("handle change...", date);
    this.setFieldValue(name, new Moment(date));
  };

  indication = () => {
    return this.state.tableBusy ? (
      <Spinner animation="grow" variant="primary" />
    ) : (
      "No data found."
    );
  };

  render() {
    if (!this.props.currentUser) {
      return <Redirect push to="/login" />;
    }

    if (this.state.busy) {
      return (
        <div className="text-center">
          <Spinner animation="grow" variant="primary" className="mt-5" />
        </div>
      );
    }

    let self = this,
      operators = self.state.operators;
    let tableColumns = [
      //{ dataField: 'id', text: 'Id' },
      { dataField: "fromDate", text: "From Date" },
      { dataField: "toDate", text: "To Date" },
      { dataField: "operator", text: "Operator" },
      { dataField: "smallBinKgWeight", text: "Small Bin Weight(Kg)" },
      { dataField: "largeBinKgWeight", text: "Large Bin Weight(Kg)" },
      { dataField: "bucketKgWeight", text: "Bucket Weight(Kg)" },
      {
        dataField: "id",
        text: "",
        formatter: self.tableActions,
        formatExtraData: { self: self },
        headerStyle: { minWidth: "100px", textAlign: "center" },
        style: { minWidth: "100px", textAlign: "center" },
      },
    ];

    return (
      <div>
        <Container className="controls">
          <Row className="menuRow pb-5">
            <TopMenu />
          </Row>

          <Row className="contentRow">
            <Col md={12}>
              <h4 className="pb-5">Manage Bucket weight</h4>
              <form
                id="reportSearch"
                onSubmit={
                  self.state.bucketEditId
                    ? self.onUpdateEditBucketWeight
                    : self.onAddBucketWeight
                }
              >
                <div className="form-container form-container--search">
                  <Row className="w-100">
                    <Col md={10} className="form-wrapper">
                      <Row>
                        <Col md={6} className="form-wrapper">
                          <DateField
                            id="date_from"
                            label="From Date"
                            selected={self.state.fields.fromDate}
                            dateChange={(selectedDate) => {
                              self.handleDateChange(
                                "fromDate",
                                selectedDate.toUTCString()
                              );
                            }}
                            maxDate={
                              self.state.fields.toDate
                                ? self.state.fields.toDate
                                : null
                            }
                          />
                        </Col>
                        <Col md={6} className="form-wrapper">
                          <DateField
                            id="date_to"
                            label="To Date"
                            selected={self.state.fields.toDate}
                            dateChange={(selectedDate) => {
                              self.handleDateChange(
                                "toDate",
                                selectedDate.toUTCString()
                              );
                            }}
                            minDate={
                              self.state.fields.fromDate
                                ? self.state.fields.fromDate
                                : null
                            }
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6} className="form-wrapper">
                          <SelectField
                            id="operator"
                            label="Operator"
                            placeholder="Operator..."
                            options={operators}
                            selectedOption={self.state.fields.operator}
                            selectChange={(event) =>
                              this.setFieldValue("operator", event.value)
                            }
                            canReset={true}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6} className="form-wrapper">
                          <TextField
                            type="number"
                            id="smallBinKgWeight"
                            label="Small Bin Weight(Kg)"
                            placeholder="small Bin Weight(Kg)"
                            value={self.state.fields.smallBinKgWeight}
                            onChange={(event) => this.handleChange(event)}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6} className="form-wrapper">
                          <TextField
                            type="number"
                            id="largeBinKgWeight"
                            label="Large Bin Weight(Kg)"
                            placeholder="Large Bin Weight(Kg)"
                            value={self.state.fields.largeBinKgWeight}
                            onChange={(event) => this.handleChange(event)}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6} className="form-wrapper">
                          <TextField
                            type="number"
                            id="bucketKgWeight"
                            label="Bucket Weight(Kg)"
                            placeholder="Bucket Weight(Kg)"
                            value={self.state.fields.bucketKgWeight}
                            onChange={(event) => this.handleChange(event)}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={2} className="form-wrapper text-right">
                      <Button
                        variant="outline-primary btn-md mb-4 "
                        className={"btn-blue d-block w-100"}
                        type="button"
                        onClick={(e) => {
                          self.resetBucketWeightFields();
                        }}
                      >
                        Reset
                      </Button>
                      {self.state.bucketEditId ? (
                        <Button
                          disabled={self.state.busyUpdate}
                          type="submit"
                          className={
                            "btn-md d-block w-100 mb-4 " +
                            (this.state.busyUpdate ? "disabled" : "btn-blue")
                          }
                        >
                          {self.state.busyUpdate ? (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          ) : (
                            "Update"
                          )}
                        </Button>
                      ) : (
                        <Button
                          disabled={self.state.busyAdd}
                          type="submit"
                          className={
                            "btn-md d-block w-100 mb-4 " +
                            (this.state.busyAdd ? "disabled" : "btn-blue")
                          }
                        >
                          {self.state.busyAdd ? (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          ) : (
                            "Add"
                          )}
                        </Button>
                      )}
                    </Col>
                  </Row>
                </div>
              </form>
            </Col>
          </Row>
          <Row>
            {!self.state.tableBusy && self.state.tableData != null ? (
              <BootstrapTable
                id="table11"
                data={self.state.tableData}
                keyField="id"
                columns={tableColumns}
                noDataIndication={self.indication}
              ></BootstrapTable>
            ) : null}
          </Row>
        </Container>
      </div>
    );
  }
}

export default withRouter(BucketWeightManager);