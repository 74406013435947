import { connect } from 'react-redux'
import Export from '../components/Export'
import Parse from 'parse'
import * as api from '../store/api'

const mapStateToProps = (state) => {
  return {
    testValue:state.service.testValue,
    currentUser:Parse.User.current(),
  };
}


const mapDispatchToProps = (dispatch) => ({
  testAsync: (callback) => {
    dispatch(api.testAsync()).then(callback);
  },
  exportCsv: (dataType, callback) => {
    dispatch(api.exportCsv(dataType)).then(callback);
  },

})


export default connect(mapStateToProps, mapDispatchToProps)(Export)
