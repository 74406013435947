
const initialState = {
    testValue: 0,
    apiStatus:"API_IDLE",
    currentUser: null,
    operators: []
}

const service = (state = initialState, action) => {
    switch (action.type) {
        case "TEST":
            return { ...state, testValue: action.data }
        case 'API_SAVING':
        case 'API_LOADING':
        case 'API_IDLE':
            return { ...state, apiStatus: action.type };
        case 'LOGIN':
        case 'LOGOUT':
        case 'SIGNUP':
            return { ...state, apiStatus: "API_IDLE", currentUser: action.data };
        case 'GET_LOGS_REPORT':
            return { ...state, apiStatus: "API_IDLE", logsReport: action.data.data };
        case 'GET_OPERATORS':
            return { ...state, apiStatus: "API_IDLE", operators: action.data.data };
        default:
            return state;
    }
}

export default service
