import React, { Component } from 'react'
import {
    withRouter
} from 'react-router-dom'
import { Container, Row, Col, Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap'

import _ from 'lodash'
import moment from 'moment'

import Page from './Page'


class Dashboard extends Page {

    state = {

    }
    
    componentDidMount() {
        
    }

    render() {
        let self = this

        let menuItems = [
            {label:"Dashboard", path:"/"},
            {label:"Bucket Weight", path:"/bucketweightmanager"},
            {label:"Settings", path:"/settings"},
            {label:"Export", path:"/export"}
        ]

        let menu = []
        var i = 0;
        _.forEach(menuItems, function(m){
            i ++;
            menu.push(
                <Button key={"menuItem_"+i} disabled={self.props.location.pathname == m.path} onClick={() => self._nagivateToPage(m.path)}>
                    {m.label}
                </Button>
            )
        })

        if(this.props.currentUser) menu.push(<Button key="logoutBtn" onClick={() => this.props.logout()}>Logout</Button>)
        
        return (
            
                <Col md={12}>
                    <ButtonToolbar>
                    <ButtonGroup aria-label="Third group">
                        {menu}
                        </ButtonGroup>
                    </ButtonToolbar>
                 </Col>
               
        )
    }
}

export default withRouter(Dashboard)