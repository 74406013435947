import 'babel-polyfill'

import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { loadingBarMiddleware } from 'react-redux-loading-bar'

import rootReducer from './store/reducers';


import {
    BrowserRouter as Router,
    Route,
} from 'react-router-dom'

import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.css';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import './styles/global.css'

import Dashboard from './containers/DashboardContainer';
import BucketWeightManager from './containers/BucketWeightManagerContainer';
import Export from './containers/ExportContainer';
import Login from './containers/LoginContainer';
import { Settings } from './components/Settings';


const loggerMiddleware = createLogger()

const store = createStore(
    rootReducer,
    applyMiddleware(
        thunkMiddleware,
        loggerMiddleware,
        loadingBarMiddleware())
)


ReactDOM.render(

    <Provider store={store}>

        <Router>
            <div>
                <Route exact path="/settings" component={Settings} />
                <Route exact path="/bucketweightmanager" component={BucketWeightManager} />
                <Route exact path="/" component={Dashboard} />
                <Route exact path="/export" component={Export} />
                <Route exact path="/login" component={Login} />
            </div>
        </Router>
    </Provider>
    ,

    document.getElementById('root')
);
