import Parse from 'parse'
import { connect } from 'react-redux';

import Login from '../components/Login';
import * as api from '../store/api'

const mapStateToProps = (state) => {
  return { 
    apiStatus:state.service.apiStatus,
    currentUser:Parse.User.current(),
  };
}


const mapDispatchToProps = (dispatch) => ({
  login: (email, password) => { dispatch(api.login(email, password)) },
})


export default connect(mapStateToProps, mapDispatchToProps)(Login)
