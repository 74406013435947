import React, { Component } from 'react'
import {
    withRouter
} from 'react-router-dom'
import { Container, Row, Col, Button, ButtonToolbar } from 'react-bootstrap'

import _ from 'lodash'
import moment from 'moment'

import Page from './Page'

import TopMenu from '../containers/TopMenuContainer';
import { CSVLink, CSVDownload } from "react-csv";

class Export extends Page {

    state = {
        busy: false,
        exportType: "",
        dailyLogExport: null,
        sharkSightingsExport: null,
        interactionLogsExport: null,
        attractantLogsExport: null,
        transferLogsExport: null
    }

    componentDidMount() {

    }

    exportCsv(dataType) {
        this.setState({ 
            busy: true, 
            exportType: dataType,
            dailyLogExport: null,
            sharkSightingsExport: null,
            interactionLogsExport: null,
            attractantLogsExport: null,
            transferLogsExport: null
        })
        this.props.exportCsv(dataType, (data) => {
            this.setState({ busy: false, exportType: "", [dataType+'Export']: data})
            
            
            
            //console.log(data);
            //return data;
            /*var element = document.createElement('a');
            //element.setAttribute('href', 'data:text/plain;charset=windows-1250,' + encodeURIComponent(data));
            element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(data));
            element.setAttribute('download', dataType + "_" + (new Date().getTime()) + ".csv");
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);*/

        })

    }

    render() {
        let self = this

        if (this.props.apiStatus == "API_LOADING") {
            return (
                <div>
                    <div>
                        Loading...
                    </div>
                </div>
            )
        }

        return (
            <Container>
                <Row className="menuRow">
                   <TopMenu />
                </Row>
                <Row className="contentRow">
                    <Col md={12}>
                        <h4 className='pb-5'>Export CSVs</h4>
                        <ButtonToolbar>
                            <Button variant="info" disabled={this.state.busy} onClick={() => this.exportCsv("dailyLog")}>Daily Logs {this.state.exportType == "dailyLog" ? " (downloading)" : ""}</Button>
                            <Button variant="info" disabled={this.state.busy} onClick={() => this.exportCsv("sharkSightings")}>Shark Sightings {this.state.exportType == "sharkSightings" ? " (downloading)" : ""} </Button>
                            <Button variant="info" disabled={this.state.busy} onClick={() => this.exportCsv("interactionLogs")}>Interaction Logs {this.state.exportType == "interactionLogs" ? " (downloading)" : ""}</Button>
                            <Button variant="info" disabled={this.state.busy} onClick={() => this.exportCsv("attractantLogs")}>Attractant Logs {this.state.exportType == "attractantLogs" ? " (downloading)" : ""} </Button>
                            {/*<Button variant="info" disabled={this.state.busy} onClick={() => this.exportCsv("transferLogs")}>Transfer Logs {this.state.exportType == "transferLogs" ? " (downloading)" : ""}</Button> */}
                        </ButtonToolbar>
                        <br/>
                        {this.state.dailyLogExport ? <CSVLink filename={"dailyLogs.csv"} data={this.state.dailyLogExport}>Download Daily Logs Export</CSVLink> : ''}
                        {this.state.sharkSightingsExport ? <CSVLink filename={"sharkSightings.csv"} data={this.state.sharkSightingsExport}>Download Shark Sightings Export</CSVLink> : ''}
                        {this.state.interactionLogsExport ? <CSVLink filename={"interactionLogs.csv"} data={this.state.interactionLogsExport}>Download Interaction Logs Export</CSVLink> : ''}
                        {this.state.attractantLogsExport ? <CSVLink filename={"attractantLogs.csv"} data={this.state.attractantLogsExport}>Download Attractant Logs Export</CSVLink> : ''}
                        {this.state.transferLogsExport ? <CSVLink filename={"transferLogs.csv"} data={this.state.transferLogsExport}>Download Transfer Logs Export</CSVLink> : ''}
                    </Col>
                </Row>

            </Container>
        )
    }
}

export default withRouter(Export)