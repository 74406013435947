import { connect } from 'react-redux'
import Dashboard from '../components/Dashboard'
import Parse from 'parse'
import * as api from '../store/api'

const mapStateToProps = (state) => {
  return {
    testValue:state.service.testValue,
    apiStatus:state.service.apiStatus,
    currentUser:Parse.User.current(),
    operators:state.service.operators,
  };
}


const mapDispatchToProps = (dispatch) => ({
  test: (val) => {
    dispatch(api.test(val));
  },
  getOperators: ( callback ) => {
    dispatch(api.getOperators()).then(callback);
  },
  getLogsReport: ( startDate, endDate, operators, callback ) => {
    dispatch(api.getLogsReport(startDate, endDate, operators)).then(callback);
  },
})


export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
