import _ from 'lodash';
import https from 'https'
import Parse from 'parse'

// Init Parse
//Parse.setAsyncStorage(AsyncStorage);
Parse.initialize("cse-app");
var path = "http://localhost:1337/parse"
if (typeof window !== 'undefined') {
    if (window.location.host.indexOf("cseapp.com") > -1) {
        path = window.location.protocol + '//' + window.location.host + '/parse';
    }
}

Parse.serverURL = path

function parseResponse(res) {

    var promise = new Promise((resolve, reject) => {

        var parsedData = null
        var errorMsg = ""

        res.setEncoding('utf8');
        let rawData = '';
        res.on('data', (chunk) => { rawData += chunk; });
        res.on('end', () => {
            try {
                parsedData = JSON.parse(rawData);

            } catch (e) {
                errorMsg = e.message
            }
            if (parsedData) {
                resolve(parsedData)
            } else {
                resolve({
                    error: errorMsg
                })
            }
        });
    });

    return promise

}


function onTest(data) {

    return {
        type: 'TEST',
        data: data,
        receivedAt: Date.now()
    }
}

export function test(data) {
    return function (dispatch) {
        let val = data;
        dispatch(onTest(val))
    }
}

export function testAsync() {
    return function (dispatch) {
        return new Promise((resolve, reject) => {
            setTimeout(function () {
                let val = Math.random();
                dispatch(onTest(val))
                resolve();
            }, 1500)


        })
    }
}

// Login
function onLogin(user) {

    return {
        type: 'LOGIN',
        data: user,
        receivedAt: Date.now()
    }
}

export function login(email, password) {
    return function (dispatch) {
        dispatch({ type: 'API_LOADING' })

        Parse.User.logIn(email, password).then(
            function (user) {

                user.fetch().then(
                    function (newUser) {
                        dispatch(onLogin(user))
                    },
                    function (err) {
                        dispatch({ type: 'API_IDLE' })
                        alert(err)
                    }
                );

            },
            function (err) {
                dispatch({ type: 'API_IDLE' })
                alert(err)
            }
        );

    }
}

//Logout

function onLogout() {

    return {
        type: 'LOGOUT',
        data: null,
        receivedAt: Date.now()
    }
}

export function logout() {
    return function (dispatch) {
        dispatch({ type: 'API_LOADING' })

        Parse.User.logOut().then(
            function (user) {
                dispatch(onLogout())
            },
            function (err) {
                dispatch({ type: 'API_IDLE' })
                alert(err)
            }
        );

    }
}


//Export

function onExportCsv() {

    return {
        type: 'EXPORT_CSV',
        data: null,
        receivedAt: Date.now()
    }
}

export function exportCsv(dataType) {
    return function (dispatch) {
        dispatch({ type: 'API_LOADING' })

        return new Promise((resolve, reject) => {
            Parse.Cloud.run("exportCsv", { dataType: dataType }).then(function (response) {
                resolve(response)
                dispatch(onExportCsv())
                
            },
                function (err) {
                    console.log("purchase error", err)
                    alert(err)
                }
            );
        })

        
        

    }
}

//Operators

function onGetLogsReport(data) {

    return {
        type: 'GET_LOGS_REPORT',
        data: data,
        receivedAt: Date.now()
    }
}

export function getLogsReport(startDate, endDate, operators) {
    return function (dispatch) {
        dispatch({ type: 'API_LOADING' })

        return new Promise((resolve, reject) => {
            //console.log("getLogsReport>>>>>>>>>>> ", operators);
            Parse.Cloud.run("getLogsReport", { startDate: startDate, endDate: endDate, operators: operators }).then(function (response) {
                resolve(response)
                dispatch(onGetLogsReport(response))
            },
                function (err) {
                    dispatch({ type: 'API_IDLE' })
                    alert(err)
                }
            );
        })
    }
}

//Operators

function onGetOperators(data) {

    return {
        type: 'GET_OPERATORS',
        data: data,
        receivedAt: Date.now()
    }
}

export function getOperators() {
    return function (dispatch) {
        dispatch({ type: 'API_LOADING' })

        return new Promise((resolve, reject) => {
            Parse.Cloud.run("getOperators").then(function (response) {
                resolve(response)
                dispatch(onGetOperators(response))
                
            },
                function (err) {
                    dispatch({ type: 'API_IDLE' })
                    alert(err)
                }
            );
        })
    }
}

//Weight bucket
function onGetBucketWeights(data) {

    return {
        type: 'GET_WEIGHT_BUCKETS',
        data: data,
        receivedAt: Date.now()
    }
}

export function getBucketWeights() {
    return function (dispatch) {
        dispatch({ type: 'API_LOADING' })

        return new Promise((resolve, reject) => {
            Parse.Cloud.run("getBucketWeights").then(function (response) {
                resolve(response)
                dispatch(onGetBucketWeights(response))
                
            },
                function (err) {
                    dispatch({ type: 'API_IDLE' })
                    alert(err)
                }
            );
        })
    }
}

function onAddBucketWeight(data) {

    return {
        type: 'ADD_BUCKET_WEIGHT',
        data: data,
        receivedAt: Date.now()
    }
}

export function addBucketWeight(fromDate, toDate, operator, smallBinKgWeight, largeBinKgWeight, bucketKgWeight) {
    return function (dispatch) {
        dispatch({ type: 'API_LOADING' })

        return new Promise((resolve, reject) => {
            //console.log("getLogsReport>>>>>>>>>>> ", operator);
            Parse.Cloud.run("addBucketWeight", { fromDate: fromDate, toDate: toDate, operator: operator, smallBinKgWeight: smallBinKgWeight, largeBinKgWeight: largeBinKgWeight, bucketKgWeight:  bucketKgWeight}).then(function (response) {
                resolve(response)
                dispatch(onAddBucketWeight(response))
            },
                function (err) {
                    dispatch({ type: 'API_IDLE' })
                    alert(err)
                }
            );
        })
    }
}

function onUpdateBucketWeight(data) {
  return {
    type: "UPDATE_BUCKET_WEIGHT",
    data: data,
    receivedAt: Date.now(),
  };
}

export function updateBucketWeight(
  id,
  fromDate,
  toDate,
  operator,
  smallBinKgWeight,
  largeBinKgWeight,
  bucketKgWeight
) {
  return function(dispatch) {
    dispatch({ type: "API_LOADING" });
    //console.log("getLogsReport 1 >>>>>>>>>>> ", values);
    return new Promise((resolve, reject) => {
      //console.log("getLogsReport 2>>>>>>>>>>> ", values);
      Parse.Cloud.run("updateBucketWeight", {
        id: id,
        fromDate: fromDate,
        toDate: toDate,
        operator: operator,
        smallBinKgWeight: smallBinKgWeight,
        largeBinKgWeight: largeBinKgWeight,
        bucketKgWeight: bucketKgWeight,
      }).then(
        function(response) {
          resolve(response);
          dispatch(onUpdateBucketWeight(response));
        },
        function(err) {
          dispatch({ type: "API_IDLE" });
          alert(err);
        }
      );
    });
  };
}

function onDeleteBucketWeight(data) {

    return {
        type: 'DELETE_BUCKET_WEIGHT',
        data: data,
        receivedAt: Date.now()
    }
}

export function deleteBucketWeight(id) {
    return function (dispatch) {
        dispatch({ type: 'API_LOADING' })

        return new Promise((resolve, reject) => {
            Parse.Cloud.run("deleteBucketWeight", { id: id }).then(function (response) {
                resolve(response)
                dispatch(onDeleteBucketWeight(response))
            },
                function (err) {
                    dispatch({ type: 'API_IDLE' })
                    alert(err)
                }
            );
        })
    }
}

// Settings
function onGetSettings(data) {
    return {
        type: 'GET_SETTINGS',
        data: data,
        receivedAt: Date.now()
    }
}

export function getSettings() {
    return function (dispatch) {
        dispatch({ type: 'API_LOADING' })

        return new Promise(async (resolve, reject) => {
            Parse.Cloud.run("getSettings").then(function (response) {
                resolve(response.data)
                dispatch(onGetSettings(response.data))
                
            },
                function (err) {
                    dispatch({ type: 'API_IDLE' })
                    alert(err)
                }
            );
        })
    }
}



function onUpdateSetting(data) {
    return {
      type: "UPDATE_SETTING",
      data: data,
      receivedAt: Date.now(),
    };
}
  
export function updateSetting(setting) {
    return function(dispatch) {
      dispatch({ type: "API_LOADING" });
      return new Promise((resolve, reject) => {
        Parse.Cloud.run("updateSetting", setting).then(
          function(response) {
            resolve(response);
            dispatch(onUpdateSetting(response));
          },
          function(err) {
            dispatch({ type: "API_IDLE" });
            alert(err);
          }
        );
      });
    };
  }