import { connect } from "react-redux";
import BucketWeightManager from "../components/BucketWeightManager";
import Parse from "parse";
import * as api from "../store/api";

const mapStateToProps = (state) => {
  return {
    operators: state.service.operators,
    apiStatus: state.service.apiStatus,
    currentUser: Parse.User.current(),
  };
};

const mapDispatchToProps = (dispatch) => ({
  getOperators: (callback) => {
    dispatch(api.getOperators()).then(callback);
  },
  getBucketWeights: (callback) => {
    dispatch(api.getBucketWeights()).then(callback);
  },
  addBucketWeight: (
    fromDate,
    toDate,
    operator,
    smallBinKgWeight,
    largeBinKgWeight,
    bucketKgWeight,
    callback
  ) => {
    dispatch(
      api.addBucketWeight(
        fromDate,
        toDate,
        operator,
        smallBinKgWeight,
        largeBinKgWeight,
        bucketKgWeight
      )
    ).then(callback);
  },

  updateBucketWeight: (
    id,
    fromDate,
    toDate,
    operator,
    smallBinKgWeight,
    largeBinKgWeight,
    bucketKgWeight,
    callback
  ) => {
    dispatch(
      api.updateBucketWeight(
        id,
        fromDate,
        toDate,
        operator,
        smallBinKgWeight,
        largeBinKgWeight,
        bucketKgWeight
      )
    ).then(callback);
  },
  deleteBucketWeight: (id, callback) => {
    dispatch(api.deleteBucketWeight(id)).then(callback);
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BucketWeightManager);
