import React, { Component } from 'react'
import {
    withRouter
} from 'react-router-dom'
import { Row, Col, Button, Form, FormControl, InputGroup, FormCheck } from 'react-bootstrap';
import { FaRegCalendar } from 'react-icons/fa';

import { FaLongArrowAltLeft, FaExclamationTriangle, FaPauseCircle, FaReplyAll, FaCheck } from 'react-icons/fa';
import * as FontAwesome from "react-icons/fa";

import Select from 'react-select';

import CreatableSelect from 'react-select/creatable';

import DatePicker from "react-datepicker";
import classnames from 'classnames';
import _ from 'lodash';

/*Properties:
labelSize, controlSize, label, id
*/
const FormGroup = ({ labelSize = 4, controlSize = 8, label, id, showLabel=true, fromGroupClass = "" , ...props }) => {
    label = label ? label : "";
    //F
    if(!showLabel){
        controlSize = 12;
    }
    return (
        <Form.Group as={Row} controlId={id} className={fromGroupClass} >
            {showLabel ?
                <Form.Label column sm={labelSize}>
                    {label}
                </Form.Label>
                :
                null
            }
            <Col sm={controlSize}>
                {props.children}
            </Col>
        </Form.Group>
    )
}


export const CheckboxField = ({ id, checked=false, label, type="text", placeholder="", ...props }) => {
    //placeholder=placeholder ? placeholder : label;
    return (
        <FormGroup label={label} id={id} {...props}>
        
            <Form.Check
                inline
                key={id}
                value={true}
                checked={(checked) ? 'checked' : ''}
                type="checkbox"
                name={id}
                id={id}
                onClick={props.onClick} 
                onChange={props.onChange}
                className={props.error ? "error" : ''}
            />
        
        </FormGroup>
            
    )
}

export const ToggleField = ({ id, checked=false, label, type="text", placeholder="", ...props }) => {
    return (
        <button 
          type="button"
          className={(checked) ? 'btn-toggle btn-toggle--checked' : 'btn-toggle'}
          key={id}
          aria-label="Select Row"
          name={id}
          id={id}
          onClick={props.onClick} 
          >{(checked) ? 'On' : 'Off'}</button>            
    )
}

export const CheckboxFieldTable = ({ name, indeterminate, checked, ...restProps }) => {
    //let input;
    console.log(restProps);
    return (
       
        <Col className="form-control-checkbox-options">
                  
            <Form.Check
                custom
                checked={checked}
                type="checkbox"
                name={name}
                onChange={() => {restProps.onChange ? restProps.onChange() : console.log('checked test')}}
                label=''
                ref={ (input) => {
                if (input) input.indeterminate = indeterminate;
                } }
            />
            
        </Col>
        
    )
}

export const SimpleTextField = ({ id, placeholder="", appendText="", ...props }) => {
    if(appendText){
        return (
            <InputGroup className="simple-input-group">
                <Form.Control type="text" className="simple" inline placeholder={placeholder} name={id}/>     
                <InputGroup.Append>
                    <InputGroup.Text className="pr-0">{appendText}</InputGroup.Text>
                </InputGroup.Append>
            </InputGroup>
        )
    }

    return (
        <Form.Control type="text" className="simple" inline placeholder={placeholder} name={id}/>      
    )
}

/*Properties:
id, label, placeholder
*/
export const TextField = ({ id, value="", label, type="text", placeholder="", ...props }) => {
    //placeholder=placeholder ? placeholder : label;
    if(type == 'hidden'){
        return <Form.Control type={type} placeholder={placeholder} name={id} value={value} onChange={props.onChange}/>
    }else{
        return (

            <FormGroup label={label} id={id} {...props}>
                <Form.Control type={type} placeholder={placeholder} name={id} value={value} onChange={props.onChange} className={props.error ? "error" : ''}/>
            </FormGroup>
                
        )
    }
}

/*Properties:
id, label, placeholder, rows
*/
export const TextareaField = ({ id, label, placeholder="", value="", cssClass="", readOnly=false, rows=5, ...props }) => {
    //placeholder=placeholder ? placeholder : label;
    const className = classnames((props.error ?  'error' : ''), (cssClass ? cssClass : ''));
    return (

        <FormGroup label={label} id={id} {...props}>
            <Form.Control as="textarea"  className={className} readOnly={readOnly ? true : false} rows={rows} value={value} placeholder={placeholder} name={id} onChange={props.textareaChange}/>
        </FormGroup>
            
    )
}


//Only using prepend group
export const InputGroupField = ({ id, label, type="text", placeholder="", prependText="", ...props }) => {
    //placeholder=placeholder ? placeholder : label;
    return (
        <FormGroup label={label} id={id} {...props}>
            <InputGroup>
                <InputGroup.Prepend>
                    <InputGroup.Text>{prependText}</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control type={type} placeholder={placeholder} name={id}/>
            </InputGroup>
        </FormGroup>
    )
}

/*Properties:
id, label, selected, dateChange
*/
export const DateField = ({ id, label, selected, minDate, maxDate, ...props }) => {
    label = label ? label : "";
    
    let date = (selected) ? Date.parse(selected) : date
    
    return (

        <FormGroup label={label} id={id} {...props}>
            <label className="date-picker-wrapper w-100">
                <DatePicker
                    selected={date}
                    onChange={props.dateChange}
                    dateFormat="dd/MM/yyyy"
                    className={props.error ? "form-control error" : 'form-control'}
                    minDate={minDate}
                    maxDate={maxDate}
                />
                <FaRegCalendar className="icon" />
            </label>
        </FormGroup>
    )
}

/*Properties:
id, label, selectedOption, options, selectChange
*/

export const SelectField = ({ id, label, selectedOption = null, options, placeholder, isLoading=false, canReset=false, ...props }) => {
    label = label ? label : "";
    let value  = null
    console.log("Selected options...", selectedOption);
    if (typeof options !== "undefined") {
      value = options.find(object => object.value === selectedOption)
      console.log("value is ", value);
      console.log("id ", id);
    }
    
    if (typeof placeholder === "undefined") {
      placeholder = "Select..."
    }

    if(canReset && value == undefined){ //Had some issue to reset value otherwise
        value = null;
    }

    return (

        <FormGroup label={label} id={id} {...props}>
            <Select
                value={value}
                options={options}
                className={props.error ? "select-control error" : 'select-control'} 
                placeholder={placeholder}
                classNamePrefix="select-control"
                onChange={props.selectChange}
                isLoading={isLoading}
            />
        </FormGroup>
    )
}

/*Properties:
id, label, selectedOption, options, selectChange, isLoading, selectCreate
*/

export const TagsField = ({ id, label, selectedOptions = [], selectedOption = null, options, isLoading = false, placeholder='Tags...', ...props }) => {
    label = label ? label : "";
    let value  = null,
        optionsFiltered = []

    return (
        
        <FormGroup label={label} id={id} {...props}>
            {
                props.selectCreate ?

                <CreatableSelect
                    isClearable
                    isDisabled={isLoading}
                    isLoading={isLoading}
                    onChange={props.selectChange}
                    onCreateOption={props.selectCreate}
                    options={options}
                    value={value}
                    className="select-control"
                    classNamePrefix="select-control"
                />
                :
                <Select
                    value={value}
                    options={options}
                    className="select-control"
                    classNamePrefix="select-control"
                    placeholder = {placeholder}
                    onChange={props.selectChange}
                />
            }

            {
                selectedOptions ? 
                    <ul className="tag-list">
                
                        {selectedOptions.map(option => {
                        
                        let tagItem = options.find((opt) => {
                            return opt.value == option;
                        });
                        
                        if(tagItem) {
                            return (<li key={tagItem.value}><a className="tag-list__remove" onClick={() => props.shouldRemoveTag(tagItem)}>Remove</a> { tagItem.label }</li>)
                        }                
                        
                        })}
    
                </ul>
                :
                null
            }
           
        </FormGroup>
    )
}

/*Properties:
id, label, selectedOption, options, radioChange
*/

export const RadioOptionsField = ({ id, label, selectedOption = null, options, ...props }) => {
    label = label ? label : "";
    //let OptionType = typeof options;
    //console.log(selectedOption);
    return (
        <FormGroup label={label} id={id} {...props}>
            {options.map(ck =>
                <Form.Check
                    inline
                    key={ck.value}
                    value={ck.value}
                    checked={ck.value == selectedOption}
                    type="radio"
                    name={id}
                    id={`${id}-${ck.value}`}
                    label={`${ck.label}`}
                    onChange={props.radioChange}
                    className={props.error ? "error" : ''} 
                />)}
        </FormGroup>
    )
}

//selectedValue example: {value: "in-progress", label: "In Progress", icon: "FaExclamationTriangle"}
export const PlainTextField = ({ id, label, value="", iconName="", iconColor="#212529", ...props }) => {
    label = label ? label : "";
    const faicon = iconName ? React.createElement(FontAwesome[iconName], {style:{color:iconColor}, className : "d-inline icon f-30"}) : "";
    return (
        <FormGroup label={label} id={id} {...props}>
            <Form.Control plaintext readOnly defaultValue={value} className={props.error ? "d-inline w-auto error" : 'd-inline w-auto'} />
            {faicon}
        </FormGroup>
    )
}