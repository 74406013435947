import Parse from 'parse'
import { connect } from 'react-redux'
import TopMenu from '../components/TopMenu'
import * as api from '../store/api'

const mapStateToProps = (state) => {
  return {
    currentUser:Parse.User.current()
  };
}


const mapDispatchToProps = (dispatch) => ({
  logout: () => { dispatch(api.logout()) },
})


export default connect(mapStateToProps, mapDispatchToProps)(TopMenu)
